import React, { useState } from 'react';
import TicketSummary from '../components/TicketSummary';
import ReservationForm from '../components/ReservationForm';
import PaystackPayment from '../PaystackPayment';
import { firestore } from '../firebase/FirebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import './Tickets.css';

function Tickets() {
  const [ticketType, setTicketType] = useState('Street');
  const [quantity, setQuantity] = useState(1);
  const [selectedDate, setSelectedDate] = useState('25');
  const [email, setEmail] = useState('');
  const [isEmailPopupVisible, setEmailPopupVisible] = useState(false);
  const [isPaymentPopupVisible, setIsPaymentPopupVisible] = useState(false); // Track the payment pop-up state
  const [paymentDetails, setPaymentDetails] = useState(null); // Store payment details
  const [error, setError] = useState(null);

  const ticketPrices = {
    '25': 0,
    '26': 0,
    '27': 3000,
  };

  const handleTicketTypeChange = (e) => setTicketType(e.target.value);

  const handleQuantityChange = (e) => setQuantity(parseInt(e.target.value, 10));

  const handleDateChange = (e) => setSelectedDate(e.target.value);

  const handleBuyClick = () => {
    if (ticketType === 'VIP') {
      return <ReservationForm />;
    }
    setEmailPopupVisible(true);
  };

  const handleEmailSubmit = async () => {
    try {
      const totalCost = quantity * ticketPrices[selectedDate];
      const reference = `REF${Math.floor(Math.random() * 1000000000 + 1)}`;

      // Save ticket data in Firestore
      const ticketData = {
        email,
        ticketType,
        date: selectedDate,
        quantity,
        totalCost,
        reference,
        createdAt: new Date().toISOString(),
      };

      await addDoc(collection(firestore, 'wtfcarnival2024', 'tickets', reference), ticketData);

      if (selectedDate === '27') {
        // Set payment details and open the payment pop-up
        setPaymentDetails({ email, totalCost, reference });
        setIsPaymentPopupVisible(true);
      } else {
        alert('Successfully signed up for free access!');
      }
    } catch (error) {
      console.error('Error saving ticket data:', error);
      alert(`Failed to complete the transaction. Error: ${error.message || error}`);
    } finally {
      setEmailPopupVisible(false);
    }
  };

  return (
    <div className="tickets">
      <h2>Access Tickets</h2>
      <form>
        <label htmlFor="ticketType">Ticket Type:</label>
        <select id="ticketType" value={ticketType} onChange={handleTicketTypeChange}>
          <option value="Street">Street Experience</option>
          <option value="VIP">VIP Experience</option>
        </select>

        {ticketType === 'Street' && (
          <>
            <label htmlFor="date">Select Date:</label>
            <select id="date" value={selectedDate} onChange={handleDateChange}>
              <option value="25">December 25th</option>
              <option value="26">December 26th</option>
              <option value="27">December 27th</option>
            </select>

            {selectedDate !== '25' && (
              <>
                <label htmlFor="quantity">Quantity:</label>
                <select id="quantity" value={quantity} onChange={handleQuantityChange}>
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                    <option key={num} value={num}>
                      {num}
                    </option>
                  ))}
                </select>
              </>
            )}
          </>
        )}
      </form>

      {ticketType === 'VIP' ? (
        <ReservationForm />
      ) : (
        <TicketSummary
          ticketType="Street Experience"
          date={selectedDate}
          quantity={selectedDate !== '25' ? quantity : 0}
          price={ticketPrices[selectedDate]}
        />
      )}

      <button
        className="buy-button"
        onClick={handleBuyClick}
        disabled={ticketType === 'Street' && selectedDate !== '25' && quantity === 0}>
        {ticketType === 'VIP' ? 'Submit Reservation' : 'Buy Tickets'}
      </button>

      {isEmailPopupVisible && (
        <div className="email-popup">
          <h3>Enter Your Email</h3>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          <button onClick={handleEmailSubmit}>Submit</button>
        </div>
      )}

      {/* Payment Pop-up */}
      {isPaymentPopupVisible && paymentDetails && (
        <div className="payment-popup">
          <h3>Proceed with Payment</h3>
          <PaystackPayment
            email={paymentDetails.email}
            amount={paymentDetails.totalCost}
            reference={paymentDetails.reference}
            onSuccess={(response) => {
              alert(`Payment Successful! Reference: ${response.reference}`);
              setIsPaymentPopupVisible(false); 
              setPaymentDetails(null); 
            }}
            onClose={() => {
              alert('Transaction was not completed.');
              setIsPaymentPopupVisible(false); 
              setPaymentDetails(null); 
            }}
          />
        </div>
      )}

      {/* Error Popup */}
      {error && (
        <div className="error-popup">
          <p>{error.message}</p>
          <button onClick={() => setError(null)}>Close</button>
        </div>
      )}
    </div>
  );
}

export default Tickets;
