import React, { useState } from 'react';
import { firestore } from '../firebase/FirebaseConfig';  // Import firestore from your firebase.js
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import "./ReservationForm.css";

function ReservationForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [numberOfPeople, setNumberOfPeople] = useState('1');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the reservation object
    const reservation = {
      name,
      email,
      numberOfPeople: parseInt(numberOfPeople, 10),  // Convert to integer
      timestamp: new Date(), // Add timestamp for when the reservation was made
    };

    try {
      // Add reservation to Firebase Firestore
      const docRef = await addDoc(collection(firestore, "tickets"), reservation);
      console.log("Reservation successfully added with ID: ", docRef.id);
      alert(`Reservation for ${name}, ${email}, ${numberOfPeople} people submitted!`);
      
      // Reset form fields after submission
      setName('');
      setEmail('');
      setNumberOfPeople('1');
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Error submitting your reservation. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="reservation-form">
      <h3>Reserve Your VIP Spot</h3>
      <input 
        type="text" 
        placeholder="Your Name" 
        value={name} 
        onChange={(e) => setName(e.target.value)} 
        required 
      />
      <input 
        type="email" 
        placeholder="Your Email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        required 
      />
      <label htmlFor="guests">Number of Guests:</label>
      <select 
        id="guests" 
        value={numberOfPeople} 
        onChange={(e) => setNumberOfPeople(e.target.value)} 
        required
      >
        {Array.from({ length: 15 }, (_, i) => i + 1).map((num) => (
          <option key={num} value={num}>
            {num}
          </option>
        ))}
      </select>
      <button type="submit">Submit Reservation</button>
    </form>
  );
}

export default ReservationForm;
