import React, { useState } from "react";
import ReservationForm from "../components/ReservationForm";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./Home.css";
import Countdown from "react-countdown";
import { Link } from 'react-router-dom';



// import Logo from "../images/carnival-bg.png";
// import flyer from "../images/WTF CARNIVAL.jpg";

function Home() {
  const [showReservationForm, setShowReservationForm] = useState(false);

  const toggleReservationForm = () => {
    setShowReservationForm(!showReservationForm);
  };

  const decemberDate = new Date(2024, 11);

  const targetDate = new Date("2024-12-25T00:00:00");

  return (
    <div className="mainContainer">

      <section className="banner">
        <div className="banner-container">

          <div className="banner-text">
            <button onClick={toggleReservationForm} className="reservation-button">
                    Book Reservations
            </button>
          </div>


        <div className="calendar-section">
            <h2>December 2024 Calendar</h2>

            <Calendar
              value={decemberDate}
              defaultView="month"
              minDetail="month"
              maxDetail="month"
              showNavigation={false}
              showNeighboringMonth={false}
              tileContent={({ date, view }) => {
                if (view === 'month' && date.getMonth() === 11) { // Ensure it's December
                  if (date.getDate() === 25) return <span className="custom-day">W</span>;
                  if (date.getDate() === 26) return <span className="custom-day">T</span>;
                  if (date.getDate() === 27) return <span className="custom-day">F</span>;
                }
                return null;
              }}
              tileClassName={({ date, view }) => {
                if (view === 'month' && date.getMonth() === 11) {
                  if (date.getDate() === 1) {
                    return "remove-background"; // Add custom class for Dec 1st
                  }
                  if ([25, 26, 27].includes(date.getDate())) {
                    return "replace-numbers";
                  }
                }
                return null;
              }}
              className="custom-calendar"
            />

              <div className="countdown-timer">
                <h3>Countdown to December 25th</h3>
                <Countdown
                  date={targetDate}
                  renderer={({ days, hours, minutes, seconds }) => (
                    <p>
                      {days}D <span className="colon">: </span>
                      {hours}H <span className="colon">: </span>
                      {minutes}m <span className="colon">: </span>
                      {seconds}s
                    </p>
                  )}
                />
              </div>
        </div>
      </div>
    </section>

    

    <section className="press-release">
    <h2>Press Release</h2>

    <div className="infoContainer">
      <p>
        Day 1: Welcome to the Red Room Christmas Party! The carnival access is free, and the club access is by reservation. 
        Join us for a high-energy street carnival on Onajimi Street, Gbagada, with vendors selling grills and street food, refreshing drinks, 
        DJs, hypemen, dancers, and amazing performances. The party will set the tone for an unforgettable experience, so come ready to groove and enjoy!
      </p>
      <p>
        Day 2: The Vibe-Box Party is the Boxing Day bash you won't want to miss! As with Day 1, carnival access is free and the club access is by reservation. 
        Get ready for more exciting street festivities with top-tier food vendors, drinks, DJs, hypemen, dancers, and live performances. It’s going to be a vibe!
      </p>
      <p>
        Day 3: The main event of the carnival—headlined by T-I Blaze with other supporting artists! Carnival access is  3000 Naira fee. 
        The street party will be in full swing with live performances, dancers, food vendors, DJs, and drinks. As usual, the club access is by reservation.
      </p>
    </div>
  </section>


    <section className="newsletter">
      <h2>Stay Updated</h2>
      <p>Subscribe to our newsletter for the latest news and updates.</p>
      <form>
        <input type="email" placeholder="Enter your email" />
        <button type="submit">Subscribe</button>
      </form>
    </section>

    <section className="sponsors">
      <h2>Our Sponsors</h2>
      <div className="sponsor-logos">
      <img src={require("../images/ddarkroom logo.jpeg")} alt="Sponsor 1" className="sponsorsImg" />
      <img src={require("../images/hope autos logo.jpeg")} alt="Sponsor 1" className="sponsorsImg" />
      </div>
    </section>
    {/* Hidden Admin Link */}
    <div className="admin-link">
        <Link to="/admin">Go to Admin Page</Link>
      </div>
    </div>
  );
}

export default Home;
