import React from 'react';
import ReservationForm from '../components/ReservationForm';

function Reserve() {
  return (
    <div className="reserve">
      <ReservationForm />
    </div>
  );
}

export default Reserve;
