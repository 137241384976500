import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Event from "./pages/Event";
import Reserve from "./pages/Reserve";
import Tickets from "./pages/Tickets";
import AdminPage from "./pages/AdminPage";
import "./App.css";

function App() {
  const [showImage, setShowImage] = useState(true); // State to toggle image visibility

  const handleCancelClick = () => {
    setShowImage(false); // Hide the image when cancel is clicked
  };

  return (
    <Router>
      <div className={`mainContainer ${showImage ? "no-scroll" : ""}`}>
        {/* Image and Cancel Button */}
        {showImage && (
          <div className="imageContainer">
            <img
              src={require("./images/WTF CARNIVAL BLAZING.jpg")}
              alt="WTF Carnival"
              className="introImage"
            />
            <button className="cancelButton" onClick={handleCancelClick}>
              &times; {/* Cancel icon */}
            </button>
          </div>
        )}

        <div className="appNavBar">
          <Navbar />
        </div>

        <div className="routeContainer">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/event" element={<Event />} />
            <Route path="/reserve" element={<Reserve />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/admin" element={<AdminPage />} />
          </Routes>
        </div>

        
      </div>
    </Router>
  );
}

export default App;
