import React, { useState } from 'react';
import { PaystackButton } from 'react-paystack';
import { QRCodeCanvas } from 'qrcode.react';  // Corrected import
import { firestore } from './firebase/FirebaseConfig';  // Adjusted the import path
import './PaystackPayment.css';  
import { collection, addDoc } from 'firebase/firestore';

const PaystackPayment = ({ email, amount, reference, onSuccess, onClose }) => {
  const [error, setError] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null); 
  const [ticketDetails, setTicketDetails] = useState(null); 

  const publicKey = 'pk_live_e13736535396808fb26fdf58458a2ffc3a201a47'; 

  const handleError = (err) => {
    setError(err);  
  };

  const handleSuccess = async (reference) => {
    try {
      const ticketData = {
        reference,
        email,
        amount,
        date: new Date(),
      };

      // Insert ticket details into Firestore
      await addDoc(collection(firestore, 'tickets'), ticketData);

      // Generate QR code
      const qrCodeDataUrl = await QRCodeCanvas.toDataURL(reference); // Using reference number for QR code

      // Set ticket details and show success message
      setTicketDetails({ ...ticketData, qrCodeDataUrl });
      setPaymentStatus('Payment was successful!');

      // Send email using mailto link
      sendEmail(email, ticketData, qrCodeDataUrl);
    } catch (error) {
      setPaymentStatus('Payment failed!');
      console.error('Error saving ticket details:', error);
    }
  };

  const sendEmail = (email, ticketData, qrCodeDataUrl) => {
    const subject = 'Your Ticket Purchase Confirmation';
    const body = `
      Hi,

      Your payment was successful! Here are your ticket details:

      - Reference: ${ticketData.reference}
      - Amount: ${ticketData.amount}
      - Date: ${ticketData.date}

      Please find your QR code below:

      [QR Code Image Link: ${qrCodeDataUrl}]

      Best regards,
      Your Team
    `;
    
    window.location.href = `mailto:${email}?subject=${subject}&body=${encodeURIComponent(body)}`;
  };

  const componentProps = {
    email,
    amount: amount * 100, // Convert amount to kobo
    reference,
    publicKey,
    text: "Pay Now",
    onSuccess: handleSuccess,
    onClose,
    onError: handleError,
  };

  return (
    <div>
      {error && (
        <div className="error-popup">
          <p>{`Error: ${error.message || 'An unknown error occurred.'}`}</p>
          <button onClick={() => setError(null)}>Close</button>
        </div>
      )}

      {paymentStatus && <div className="payment-status">{paymentStatus}</div>}

      {ticketDetails && (
        <div className="ticket-details">
          <p>Ticket Details:</p>
          <p>Reference: {ticketDetails.reference}</p>
          <p>Email: {ticketDetails.email}</p>
          <p>Amount: {ticketDetails.amount}</p>
          <QRCodeCanvas value={ticketDetails.reference} />  {/* Using QRCodeCanvas */}
        </div>
      )}

      <PaystackButton {...componentProps} className="paystack-button" />
    </div>
  );
};

export default PaystackPayment;
